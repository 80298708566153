module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Convideo","name":"Convideo","icon":"src/assets/logo-icon.svg","start_url":".","display":"standalone","theme_color":"#121212","background_color":"#121212","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"aeb2578143485b607f13296ea894d3dd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"cacheId":"convideo","skipWaiting":true,"offlineGoogleAnalytics":true,"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"google-fonts-stylesheets"}},{"urlPattern":{},"handler":"CacheFirst","options":{"cacheName":"google-fonts-webfonts","cacheableResponse":{"statuses":[0,200]},"expiration":{"maxEntries":20,"maxAgeSeconds":31536000}}},{"urlPattern":{},"handler":"CacheFirst","options":{"cacheName":"images","expiration":{"maxEntries":60,"maxAgeSeconds":2592000}}},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"static-resources"}},{"urlPattern":{},"handler":"NetworkFirst"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149125237-2","cookieDomain":"convideo.app"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
